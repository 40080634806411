@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400');

body {margin:0; padding: 0; background: #000; color:#fff; font-family: 'Lato';}
h1 {margin:0 0 0 3vw; padding: 0; text-transform: uppercase; font-size:4vw; font-weight: bold; letter-spacing: 0.2vw; color:#fff; line-height: 7vw; letter-spacing:0.8vw; width:auto; display: inline-block;}
h2 {font-weight:normal; text-transform: none; letter-spacing: 0.2vw; padding:0; margin:0; font-size: 2.0vw; text-align: center;}
h3 {font-weight:normal; text-transform: uppercase; letter-spacing: 4px; padding:0;margin:8vh 2vw 4vh 2vw; font-size: 1.5vw; text-align: center; }
p {text-transform: none; color:#d0d0d0; letter-spacing:0px; padding:0; margin:4vh auto 4vh auto; font-size: 1.3vw; line-height: 2.3em; text-align: left; width:80%;}
a {text-decoration: none; }
a:hover {text-decoration: underline;}
a, a:visited, a:hover {color:#d0d0d0;}
li {list-style: none; text-transform: none; display: inline; padding: 0 10px 0 10px; letter-spacing:0; background: transparent;}

#first {padding-left:0;}
#main {width:100%; height :100%; position: absolute; top:0; bottom: 0; left:0; right:0; z-index: 10;}
#slideshow {width:100vw; height:37.5vw; position: absolute; top:0; left:0; display: none;}
#slideshow .slide {width:100%; height: auto;}
#slideshow .project {width:100%; height: auto;}
.project {width:100%; height :100%; position: absolute; top:0; bottom: 0; left:0; right:0; margin:0; background-repeat: no-repeat; background-position: center center; background-size: cover; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover;} 
.slide {width:100%; height :100%; position: absolute; top:0; bottom: 0; left:0; right:0; margin:0; background-repeat: no-repeat; background-position: center center; background-size: cover; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover;} 

#projectbg {z-index:0; background-image: url(/images/projects/wmoo.jpg); opacity:1; display: block;}

#root {z-index: 10; position: absolute; top:0; left:0; right:0; width: 100%; height: 100%;}


#title {z-index: 20; text-align: left; margin:2vw 0 0 3vw;  text-transform: none; font-weight: normal; font-style: italic; font-size:2.5em; width:50vw; position: absolute; top:0; left: 0; line-height: 3vw; letter-spacing: 0.5vw;}
#title span {font-size: 0.5em;}
#titlecontent {z-index: 20; text-align: left; margin:2vw 0 0 0;  text-transform: none; font-weight: normal; font-style: normal; font-size:2vw; width:30vw; position: relative; top:0; left: 0; line-height: 3vw; color:#202020;}

#menu {margin: 2em 0 0 3vw; background-color: transparent; text-align: left; position: absolute; left: 0; top: 3vw; z-index: 20;}
#menucontent {margin: 1vw 0 2vw 0; background-color: transparent; text-align: left; position: relative; left: 0; top: 0;}
#menucontent ul {margin:0 auto; padding:0;}
#menucontent a {color:#202020;}

#menu ul {margin:0 auto; padding:0;}
#menu a {color:#fff; text-decoration: none; line-height: 3vw; font-size: 1.0em;}
#menu a:hover {text-decoration: underline; cursor: pointer;}

#titlewrapper {width:100%; height:9vw; top:0; left:0; right:0; z-index: 20; position: absolute;}
#acelogo {width: 15vw; height: auto; position: fixed; bottom:0; right: 0; z-index: 30; margin:2vw 2vw 2vw 0; display: none;}

#leftmenu {width:52vw; height:auto; /*margin-left: -30vw;*/ top:0; left:0; right:0; bottom: 0; z-index: 15; position: absolute; background-image: url('/images/gradient_70.png'); background-size: contain; background-repeat: repeat-y; padding: 0 0 0 3vw; display: block;}	
#leftinner {position: absolute; top:15vh; left:0; margin:0 0 0 3vw; padding:0; opacity: 0;}	
#leftmenu ul {} 
#leftmenu li {margin:0; padding: 0; cursor: pointer; text-transform: uppercase; font-size:2vw; font-weight: bold; letter-spacing: 0.2vw; color:#fff; line-height: 5.2vw; letter-spacing:0.8vw; width:auto; display: block;}
#leftmenu p {margin:0; padding: 0; cursor: pointer; text-transform: uppercase; font-size:3vw; font-weight: bold; letter-spacing: 0.2vw; color:#fff; line-height: 6vw; letter-spacing:0.8vw; width:auto; display: inline-block;}
#leftmenu span {font-size:1vw; letter-spacing:0.1vw;}
#leftmenu p:hover {text-decoration: underline;} 
#leftmenu a, a:visited, a:hover {color:#fff;}

.menuHidden {opacity:0; margin-left:-10000px !important; 
	transition: opacity .25s ease-in-out, margin-left 0s 0.25s;
   -moz-transition: opacity .25s ease-in-out, margin-left 0s 0.25s;
   -webkit-transition: opacity .25s ease-in-out, margin-left 0s 0.25s;
}

.menuShown {
	opacity: 1 !important; margin-left:inherit;  
	transition: opacity .25s ease-in-out 0.25s, margin-left 0s 0s;
   -moz-transition: opacity .25s ease-in-out 0.25s, margin-left 0s 0s;
   -webkit-transition: opacity .25s ease-in-out 0.25s, margin-left 0s 0s;
}

#content {position: relative; top: 0; width: 960px; padding: 0 0 1vw 0; z-index: 30; background-color: #fff; margin: 0 auto 0 auto;}
#contentwrapper {position: absolute; top:100%; width:100%; padding:0; z-index: 30; background-color: #fff; display: block;}
#contentheader{display: block;}

#content h1 {color: #202020; font-size: 3.5vw; text-decoration: none; letter-spacing: 0.6vw; line-height: 4vw; font-weight: bold; border-bottom: 1px solid #dcdcdc; display: inline-block; width: 100%; margin:0; padding: 0 0 0.8vw 0;} 
#content p {color:#000; width:100%; font-size: 1em; font-weight: 300; margin:2.5em 0 1em 0; letter-spacing: 0.1em;}
#content .leftcol {width:60%; position: relative; top:0; left:0; float: left; color:#000;}
#content .leftcol video {width: 100%;}
#content .leftcol .credit {    margin-top: -1.5em; font-size: 0.8em;}
#content .rightcol {width:auto; max-width:31%; position: relative; top:0; right:0; float:right; padding: 0 1em 0 0;}
#content img {width:100%; height: auto;}

.center {text-align:center;}
.picWrapper {width:100vw; text-align:center;}
.pic {width:100vw; position: relative; margin:0 auto; width:100%; max-width: 960px;}
.pic img {width:100%; position: relative; left:0; top:0; opacity: 1;}	
.rotatorWrapper, .rotator, .rotator img {width: 100vw;}
.sectionhead {border-bottom: 0 #222 none;}

#close {position: relative; top: 0; right: 0; color: #202020; width: auto !important; display: block; margin: 1vw 1vw 0 0 !important; text-transform: none; font-size: 1em !important; text-decoration: none; text-align: right; font-weight: 400;} 
#close:hover {text-decoration: underline; cursor: pointer;}

.rightcol h2 {color:#202020; font-size: 0.9em; line-height: 2.3vw; letter-spacing:0.1vw; margin:2em 0 0.2em 0; text-align:left;}
.rightcol ul {margin: 0; padding: 0; display: inline-block;}
.rightcol li {margin: 0.7em 0 0.7em 0; padding: 0; color: #000; font-size: 0.9em; line-height: 1.8em; min-height:1.8em; letter-spacing: 0.1em; text-align: left; font-weight: 300; width: 100%; display: block;}
.rightcol a, .leftcol a {color:#000; text-decoration: underline;}
#content .rightcol img {width: 313px; margin: 2em 0 0 0;} 
#back {color: #202020; cursor:pointer; position: relative; top: 0; left: 0; display: inline-block; width: 100%; margin: 0.5em 0 10em 0 !important; font-weight: 400 !important;}
#back:hover {text-decoration: underline; }
#copy {position: absolute; right:1em; bottom:1em; display: inline-block; width: auto !important; margin: 0 0 0 0 !important; font-weight: 300 !important; font-size: 0.8em !important; z-index: 50; color: #000;}

#social {position: absolute; top: 2vw; right: 2vw; z-index: 50; margin: 0; display: none;}
#social img {width: 25px; margin: 0 0 0 8px;}

.full {width:80vw !important; margin-top: 0 !important;}

/*iframe {width: 576px !important; height:324px !important;}*/

@media all and (max-width: 1100px) {
  #leftinner {top:12.5vw;}
}

@media all and (max-width: 980px) {
  #content {width: 90vw;}
  #content .leftcol, #content .rightcol {width: 100%; padding: 0;}	
  #title {font-size:1.6em;}
  #menu {margin-top:2.0em;}
  #menu a{font-size: 1.0em;}
  #leftinner {top:15vw;}
  #content .rightcol {margin-bottom: 2em;}
  #back {text-align: left;}
  #content .rightcol img {width: 100%;} 
}

@media all and (max-width:650px) {
  h2 {font-size:6vw;}
  h3 {font-size:4vw; letter-spacing: 3px;}
  p {font-size: 4.5vw; line-height: 8vw; width: 90%;}
  #credits {font-size:5vw;}
  .credits {font-size:4vw;}
  .project {height: auto; top: 28vw;}
  #title {text-align: left; font-size: 8vw; width: 90vw; margin: 7vw 0 0 5vw; position: relative; display: inline-block;} 
  #menu {text-align: left; margin-bottom: 2vw; margin-top: 2vw; margin-left:5vw; position: relative; display: inline-block; width: 70vw;}
  #acelink {position: fixed; display: block; width: 100vw; height: 22vw; bottom: 0; left:0; right:0; text-align: center; z-index: 100; background-color: #000;}
  #acelogo {position:relative; top:0; margin:1em auto 1em auto; width: 50vw;}
  #menu a{font-size: 5vw;}
  #first {padding-left:0;}
  #leftmenu {top:0; /*width:95vw;*/ margin:0; display: block; bottom: 0;}
  #leftmenu a {font-size: 8vw; line-height: 15vw;}
  #leftmenu span {display: none;}
  #content {width:100vw;}
  #main, .slide {height: 70vh;}
  #main {position: relative; top:28vw !important; display: block;}
  #contentwrapper {position: absolute;}
  #titlewrapper {height: 28vw; background-color: #000; z-index: 50;}
  #leftinner {top:25vh; margin-left:5vw; width:94vw;}
  #close {margin:0 3vw 0 0 !important;}
  #content h1 {font-size: 10vw; margin: 0 0 0 5vw; line-height: 12vw; width: 90vw;}
  #content .leftcol, #content .rightcol {width: 100vw; padding: 0;}
  #content .rightcol {margin-bottom: 2em; max-width: 100%;}
  #content .rightcol li {font-size:1em; width:90vw;}
  #content .rightcol h2 {font-size:1em; line-height: 1em;}
  #content p {width: 90vw; margin-left: 5vw; line-height: 2.5em;}
  .rightcol h2, .rightcol ul {margin-left: 5vw;}
  #back {text-align: left; width: 90vw !important; margin-left:5vw !important;} 
  #content .rightcol img {
    width: 90vw;
    margin: 2em -1em 0 5vw;
  }
  #copy {text-align: center; width: 100vw !important; right: 0; left: 0;} 
  #social {z-index: 110; top:5vw; right:5vw;}
  iframe {width:90vw !important; height:50.6vw !important; margin-left: 5vw;}
/*
a:hover {text-decoration: none !important;}
a:selected {text-decoration: none !important;}
a:visited {text-decoration: none !important;}
a:active {text-decoration: none !important;}
*/
}