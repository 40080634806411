
.slide-wrapper {background-color: #fff; width:100%; height: 410px; position: relative; margin-top: 2vw; overflow: hidden;}

.slide-wrapper .slide-child {position: absolute; top:0; left:0;}

.slide-hidden {
  opacity:0.01;
  transition: opacity 2.0s ease-in-out;
  -moz-transition: opacity 2.0s ease-in-out;
  -webkit-transition: opacity 2.0s ease-in-out;
}

.slide-shown {
  opacity:1;
}

.slide-fade-top {
  opacity:1; 
  transition: opacity 2.0s ease-in-out;
  -moz-transition: opacity 2.0s ease-in-out;
  -webkit-transition: opacity 2.0s ease-in-out;
}

.slide-none {
display: none;
}

@media all and (max-width:980px) {
  .slide-wrapper {
    height: 65vw;
    width: 100%;
    margin-top: 6vw;
  }
  #content .slide-wrapper img {width: 90vw; margin-left: 0;}
}

@media all and (max-width:650px) {

  #content .slide-wrapper img {margin-left: 5vw;}
}